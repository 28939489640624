module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Flitdesk","short_name":"Flitdesk","description":"Flitdesk is the all-in-one solution to simplify the management of offices and modern workspaces","start_url":"/fr","background_color":"#e9e9e9","theme_color":"#ff5a5a","display":"minimal-ui","icon":"static/logos/favicon-196x196.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"a525d70411c6ab48f8960ced1a76d1b5"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager-delayed/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-5CG9GNT","includeInDevelopment":true,"debug":true,"routeChangeEventName":"gatsby-route-change","defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}}},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
