// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-features-js": () => import("./../../../src/pages/features.js" /* webpackChunkName: "component---src-pages-features-js" */),
  "component---src-pages-home-js": () => import("./../../../src/pages/home.js" /* webpackChunkName: "component---src-pages-home-js" */),
  "component---src-pages-integration-js": () => import("./../../../src/pages/integration.js" /* webpackChunkName: "component---src-pages-integration-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-solution-asset-managers-js": () => import("./../../../src/pages/solution-asset-managers.js" /* webpackChunkName: "component---src-pages-solution-asset-managers-js" */),
  "component---src-pages-solution-coworking-js": () => import("./../../../src/pages/solution-coworking.js" /* webpackChunkName: "component---src-pages-solution-coworking-js" */),
  "component---src-pages-solution-entreprise-js": () => import("./../../../src/pages/solution-entreprise.js" /* webpackChunkName: "component---src-pages-solution-entreprise-js" */),
  "component---src-pages-solution-startups-js": () => import("./../../../src/pages/solution-startups.js" /* webpackChunkName: "component---src-pages-solution-startups-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */)
}

